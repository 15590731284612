import React, {useState, useEffect, useContext} from 'react'
import styled from 'styled-components'
import OrderItem from '../../OrderItem'
import CartContext from '../../Context/CartContext'
import EllipsisLoader from '../../EllipsisLoader'
import IconCheck from '../../../images/icons/icon-success-small.svg'
import IconExclamation from '../../../images/icons/icon-exclamation.svg'
import IconInfo from '../../../images/icons/icon-info.svg'
import {isLoggedIn} from '../../../services/auth'

const OrderConfirmWrapper = styled.div`
  .success-message {
    background: #fafafa;
    padding: 30px;
    .order-id {
      background: rgba(200, 200, 200, 0.3);
      display: inline-block;
      padding: 5px 10px;
      border-radius: 3px;
    }
    .pay-error-code {
      color: grey;
    }
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-gap: 20px;
    align-items: center;
    h3 {
      margin: 0;
    }
    p {
      margin: 0;
    }
    img {
      width: 60px;
    }
  }
  @media (max-width: 480px) {
    .success-message {
      grid-template-columns: 1fr;
      text-align: center;
      justify-items: center;
    }
    h2 {
      text-align: center !important;
    }
  }
`

const OrderConfirmed = ({orderId}) => {
  const [order, setOrder] = useState(null)
  const [retryCount, setRetryCount] = useState(10)
  const [orderStatus, setOrderStatus] = useState('loading')
  const [error, setError] = useState('')
  const {getCartContents, getOrderById} = useContext(CartContext)

  // When we need to fetch the fresh order data
  const fetchOrder = () => {
    // Fetch the order
    if (orderId) {
      getOrderById(orderId)
        .then(wcOrder => {
          console.log('Order data', wcOrder)
          if (wcOrder) {
            setOrder(wcOrder)
          }
        })
        .catch(error => {
          console.log(error)
          setError(error)
        })
    } else {
      console.log('Attempted to fetch order without token or order ID')
    }
  }

  const evaluateOrder = () => {
    if (!isLoggedIn()) {
      setOrderStatus('no-login')
      return
    }
    if (order) {
      if (
        order.status === 'processing' ||
        order.status === 'completed' ||
        order.status === 'refunded'
      ) {
        setOrderStatus('success')
      } else if (retryCount < 1) {
        setOrderStatus('timeout')
      } else if (order.payment_method === 'bacs') {
        setOrderStatus('success-eft')
      } else if (order.status === 'on-hold') {
        setOrderStatus('processing')
        retryWhileProcessing()
      } else {
        setOrderStatus('failed')
      }
    } else if (!error && orderId) {
      fetchOrder()
    } else {
      setOrderStatus('loading')
    }
  }

  // This function will run a timeout function and fetch updated order data for some time
  const retryWhileProcessing = () => {
    setRetryCount(prev => prev - 1)
    if (retryCount > 0) {
      setTimeout(() => {
        console.log('Retry fetching order...')
        fetchOrder()
      }, 2000)
    } else {
      evaluateOrder()
    }
  }

  // If the order or order ID changes
  useEffect(() => {
    evaluateOrder()
  }, [order, orderId])

  // Cart has probably been deleted. Update it so that "Click to Pay" button doesn't bomb out
  useEffect(() => {
    getCartContents()
  }, [])

  let topHeading = ''
  let secondHeading = ''
  let topIcon = ''
  let details = ''
  let detailsExtra = ''

  if (orderStatus === 'loading') {
    return <EllipsisLoader height="600px" />
  }
  if (orderStatus === 'success') {
    topHeading = 'Thank you!'
    topIcon = IconCheck
    secondHeading = 'Your Order is confirmed'
    details =
      'You will receive a confirmation email with your order details ' +
      'shortly. You can also view and pay your orders any time from your ' +
      'account page.'
  } else if (orderStatus === 'success-eft') {
    topHeading = 'Thank you!'
    topIcon = IconCheck
    secondHeading = 'Your Order is confirmed'
    details =
      'You will receive a confirmation email with your order details ' +
      'shortly. Once funds have cleared in our bank account, your order will ' +
      'be shipped. You can view your order status here or on your account page ' +
      'any time.'
  } else if (orderStatus === 'processing') {
    topHeading = 'Thank you!'
    topIcon = IconInfo
    secondHeading = 'Processing'
    details =
      'Your payment is being processed. The status will update when your payment completes'
  } else if (orderStatus === 'no-login') {
    topHeading = 'Oh no!'
    topIcon = IconExclamation
    secondHeading = 'You are not logged in'
    details =
      'Please log in to view your order. You can return to this page or go to My Account to see your order status'
  } else if (orderStatus === 'timeout') {
    topHeading = 'Oh no!'
    topIcon = IconExclamation
    secondHeading = 'Something went wrong'
    details =
      'Your order took way too long to process! Refresh the page to check again, or contact support for help'
  } else {
    topHeading = 'Oh no!'
    secondHeading = 'Your payment has failed'
    topIcon = IconExclamation
    details =
      order.order_notes.length > 0
        ? order.order_notes[0].comment_content
        : 'Your payment attempt seems to have failed'
    detailsExtra =
      'You can attempt your payment again by clicking on the icon below. You can also view and pay your orders any time from your ' +
      'account page.'
  }

  return (
    <OrderConfirmWrapper>
      {error ? (
        <p>{error}</p>
      ) : (
        <>
          <h2 className="top">{topHeading}</h2>
          <div className="success-message">
            <img src={topIcon} alt="" />
            <div>
              <h3>{secondHeading}</h3>
              <p className="pay-error-code">{details}</p>
              {detailsExtra && <p>{detailsExtra}</p>}
            </div>
          </div>
          <OrderItem givenOrder={order} flush />
        </>
      )}
    </OrderConfirmWrapper>
  )
}

export default OrderConfirmed
