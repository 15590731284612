import React from 'react'
import querystring from 'querystring'
import {navigate} from 'gatsby'
import Layout from '../components/Layout'
import {CartPageStyles, Inner} from './cart'
import OrderConfirmed from '../components/Checkout/OrderConfirmed'
import StatsCounter from '../components/shared/StatsCounter'

const OrderSuccess = ({location}) => {
  let orderId = null
  if (location.search.includes('?orderid')) {
    const queryval = querystring.parse(location.search.split('?')[1])
    orderId = queryval.orderid
  } else if (typeof window !== 'undefined') {
    navigate('/profile/')
  }

  return (
    <Layout location={location} flush>
      <CartPageStyles>
        <Inner>
          <OrderConfirmed orderId={orderId} />
        </Inner>
      </CartPageStyles>
    </Layout>
  )
}
export default OrderSuccess
